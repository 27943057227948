.cart-icon{
    position: fixed;
    z-index: 1000;
    transition: right 1000ms ease-in-out;
 
 }
 .cart-scrolled-50{
    right: -4000px;
    opacity: 0;
 }
 .cart-scrolled-80{
    top: 20px;
    right: 40px;
    opacity: 1;
 }
 
 .cart-not-scrolled{
     top: 50px;
     right: 40px;
     opacity: 1;
 }
 
 @media screen and (max-width:992px){

.mobile-cart-position{
    top: 20px;
    right: 24px;

}

 } 