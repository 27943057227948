/* src/MainHomePage.css */
.MainHome {
    height: 100vh;
    margin: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.MainHome .home-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust opacity here (0.5 is 50% opacity) */
    z-index: 1; /* Ensure overlay is above the video */
}

.content {
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
    z-index: 2; /* Ensure content is above overlay */
}

.MainHome .btn {
    font-size: 16px;
    background: rgb(128,178,65);
    background: linear-gradient(90deg, rgba(128,178,65,1) 0%, rgba(16,104,54,1) 59%);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color:white;
    font-weight: bold;
    width: 200px;
}

.MainHome .btn:hover {
    background: #106836 ;
    /* color: black; */
}

@media screen and (max-width:920px){
    .content {
        display: flex;
        flex-direction: column;
    }
    .MainHome .btn {
        background: linear-gradient(90deg, rgba(128,178,65,1) 0%, rgba(16,104,54,1) 59%);
    }
    .background-video {
        object-fit: fill;
    }
  }
