.product {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
}
.img-container {
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  border-radius: 20px;
}

.img-div {
  position: relative;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid #106836;
  overflow: hidden;
  transition: all ease-in-out 200ms;
}
.img-div:hover {
  border: 2px solid #80B241;
}

.product img {
  margin: 0 auto;
  transition: scale 800ms ease-in-out;
  border: 1px solid white;
  border-radius: 20px;
}

.product-details {
  padding: 10px;
  /* height: 180px; */
}


.img-container:hover img {
  scale: 1.3;
  /* transform: rotateY(360deg); */
}

.add-btn{
  /* background: #F2F6D5; */
  font-weight: bold;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  transition: all ease-in-out 200ms;
}
.add-btn:hover{
  background: #80B241;
  color: white;
  font-weight: bold;
  width: 200px;
  height: 40px;
  border-radius: 10px;
}

.limit-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 992px) {
  .product {
    /* width: 100%; */
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 640px) {
  .product {
    width: 92%;
    text-align: left;
    font-size: 14px;
    /* height: 350px; */
    /* border: none; */
    /* border: 2px solid #106836; */

  }
  .product img {
    /* height: 180px; */
    /* margin-bottom: 50px; */
  }

  .list-btn{
    margin-top: 5px;
    font-weight: bold;
    font-size: small;
  }

  .product-details {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* height: 100px; */
  }
  
  .list-btn{
    padding: 6px;

  }
  .img-container{
    height: 80%; 
    width: 100%;
  }

  .img-container:hover .details-btn {
    display: none;
  }

  .add-btn{
    width: 150px;
  }

}
