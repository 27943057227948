.most-favorite{
    position: relative;
    /* background: #abcacb5b; */
    background: #ffffff;
}

.featured-collections::after{
    top: 100%;
}

.responsive-iframe {
    width: 100%;
    height: 80vh; /* 16:9 aspect ratio (1920x780) */
    max-height: 100vh; /* Set maximum height to 100% of viewport height */
}

/* For small screens, adjust the aspect ratio */
@media screen and (max-width: 767px) {
    .responsive-iframe {
        height: 100vw; /* 1:1 aspect ratio for small screens */
    }
}

@media screen and (max-width: 640px) {
    .most-favorite{
        margin-top: 0;
    }
}