.footer-container{
    letter-spacing: 0.5px;
    line-height: 21px;
}
.footer-container a{
    color: black;
    font-size: 14px;
}
span{
    color: green;
    font-size: 16px;
}
