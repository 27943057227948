.scroll-bar {
    position: fixed;
    top: 113px; /* Adjust this value according to your navbar height */
    left: 0;
    height: 4px;
    z-index: 999; /* Adjust this value as needed */
    transition: all ease-in-out 200ms;
    border-radius: 0px 10px 10px 0px;
  }

  @media screen and (max-width:940px){
    .scroll-bar {
        top: 70px; /* Adjust this value according to your navbar height */
      }
  }